import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  loading: boolean;
  text: string;
}

export default (): AlpineComponent<State> => {
  return {
    async click(event: Event) {
      event.preventDefault();

      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      if (typeof this.$root.dataset["action"] !== "string") {
        return;
      }

      if (this.loading) {
        return;
      }

      const form = event.target.closest("form");

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      const body = new FormData(form);

      body.set(
        "prefix",
        this.$root.querySelector("textarea")?.getAttribute("name") ?? "",
      );

      this.loading = true;

      const response = await fetch(this.$root.dataset["action"], {
        body,
        headers: {
          Accept: "text/html",
        },
        method: "POST",
      });

      if (response.status === 200) {
        this.text = await response.text();
        this.$dispatch("input");
      }

      this.loading = false;
    },
    init() {
      this.text = this.$el.querySelector("textarea")?.innerHTML ?? "";
    },
    loading: false,
    text: "",
  };
};
